<template>
  <div class="bg-white rtl">
    <van-nav-bar
      :title="cateDetail.name"
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        قايتۋ
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
        باس بەت
      </div>
    </van-nav-bar>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="isEmpty ? 'قازىرشە مازمۇن جوق' : ''"
      :error.sync="error"
      error-text="مازمۇن ىزدەلۋ ۇستىندە، بۇل جەردى باسىڭىز"
      @load="onLoad"
      loading-text="ىزدەلۋدە"
    >
      <!-- <CourseItem :hots="courses" /> -->
      <GridItem v-if="cateDetail.display == 2" :hots="courses" />
      <ThreeGrid v-else-if="cateDetail.display == 3" :hots="courses" />
      <CourseItem v-else :hots="courses" />
    </van-list>
    <van-empty v-show="isEmpty" />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { initShareInfo } from '@/utils/util'
import GridItem from '@/components/Grid-item'
import CourseItem from '@/components/Course-item'
import ThreeGrid from '@/components/Three-Grid'
import { categoryDetail, getCourseByCate } from '@/api/home'
export default {
  data () {
    return {
      courses: [], // 课程列表数据
      cateDetail: {},
      pages: {
        cateId: null,
        pagenum: 1,
        pagesize: 9,
        total_pages: null,
        total: null,
        filmName: ''
      },
      loading: false, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false // 是否加载失败
    }
  },
  components: {
    GridItem,
    CourseItem,
    ThreeGrid
  },
  mounted () {
    this.queryData()
  },
  computed: {
    isEmpty () {
      return this.finished && !this.pages.total && !this.courses.length
    }
  },
  methods: {
    // 监听底部事件
    async onLoad () {
      try {
        // 1. 请求获取数据
        const { data } = await getCourseByCate({
          ...this.pages,
          id: this.$route.query.id
        })

        // 2. 把数据添加到 courses 数组中
        const results = data.data
        this.courses.push(...results)
        this.pages.total = data.meta.total

        // 3. 设置本次加载中 loading 状态结束
        this.loading = false
        const { pagenum, pagesize } = this.pages
        // 4. 判断数据是否加载结束
        if (pagenum * pagesize >= data.meta.total) {
          // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
          this.finished = true
        } else {
          // 更新获取下一页数据的时间戳
          this.pages.pagenum = pagenum + 1
        }
      } catch (err) {
        console.log(err)
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
      }
    },
    async queryData () {
      const { data: cateDetail } = await categoryDetail(this.$route.query.id)
      this.cateDetail = cateDetail.parent_category
      wx.ready(() => {
        initShareInfo({
          title: `${cateDetail.parent_category.name} - نۇر دومبىرا ساباقحاناسى `, // 分享标题
          imgUrl: cateDetail.parent_category.ad_cover // 分享图标
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .bg-white {
    padding-top: 92px;
  }
</style>
